/**
 * DEFINITIONS OF KEY USED IN LOCALSTORAGE APP
 */

export enum EStorageKey {
  TRANSLATE_SERVICE = '@translation_service',
  USER_PARAMS = '@user_params_account',
  USER_PARAMS_NOT_AUTH = '@user_params_not_auth',
  CART_USER = '@cart_user_boutique',
  USER_TOKEN_NOTIFICATIONS = '@user_token_notifications_v5',
  COUPON_USER = '@coupon_user_boutique',
  RATING_APP = '@rating_app_user',
  RESTORE_ID = '@RESTORE_ID',
  SMART_BANNER = '@SMART_BANNER',
  ORDER_INFOS = '@ORDER_INFOS',
}
