/* eslint-disable */

import { Grid, Typography, Link, Box } from '@mui/material';
import { styles, Logo } from './styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

// my custom import
import GooglePlayBadge from '../../../assets/google-play-badge.png';
import AppStoreBadge from '../../../assets/app-store-badge.svg';
import themesConfig from '../../../config/themes.config';
import { useAppSelector } from '../../../store/store';

const FooterComponent = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.loggedIn);
  return (
    <Grid
      container
      spacing={2}
      sx={[
        styles.container,
        isAuthenticated ? {display:{xs: 'none', md:'flex', sm:'none', lg:'flex'}} : {} ]}
        id="footer-page">
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Logo src={themesConfig.LOGO_APP_WHITE} />
        <Box sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          display: 'flex',
          
        }}>

          <Link
            target="_blank"
            href="https://apps.apple.com/fr/app/please-livraison-%C3%A0-domicile/id1130427303">
            <img
              src={AppStoreBadge}
              srcSet={AppStoreBadge}
              style={{
                height: '35px'
              }}
              loading="lazy"
            />
          </Link>

          <Link
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.pleaseit.please">
            <img
              src={GooglePlayBadge}
              srcSet={GooglePlayBadge}
              style={{
                height: '56px'
              }}
              loading="lazy"
            />
          </Link>

        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Typography sx={styles.title}>Liens utiles</Typography>

        <Typography sx={styles.description}>
          <Link
            target="_blank"
            sx={{ color: '#fff', textDecoration: 'none' }}
            href='https://mw.please-it.com/assets/Please/legal_documents/mentions-legales.html' //eslint-disable-line
          >
            Mentions légales
          </Link>
        </Typography>

        <Typography sx={styles.description}>
          <Link
            target="_blank"
            sx={{ color: '#fff', textDecoration: 'none' }}
            href="https://mw.please-it.com/assets/Please/legal_documents/conditions-generales-utilisation-habitants.html" //eslint-disable-line
          >
            Condition générales d'utilisations
          </Link>
        </Typography>

        <Typography sx={styles.description}>
          <Link
            target="_blank"
            sx={{ color: '#fff', textDecoration: 'none' }}
            href="https://mw.please-it.com/assets/Please/legal_documents/politique-de-confidentialite.html" //eslint-disable-line
          >
            Politique de confidentialité
          </Link>
        </Typography>

        <Typography sx={styles.description}>
          <Link
            target="_blank"
            sx={{ color: '#fff', textDecoration: 'none' }}
            href="https://mw.please-it.com/assets/Please/legal_documents/politique-concernant-les-cookies.html" //eslint-disable-line
          >
            Charte d'usage des cookies
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Typography sx={styles.title}>Partagez avec vos amis</Typography>

        <Typography sx={styles.description}>
          <Link
            target="_blank"
            href="https://fr-fr.facebook.com/PleaseAppFR/">
            <FacebookIcon fontSize="large" sx={styles.socialIcon} />
          </Link>
          <Link
            target="_blank"
            href="https://www.instagram.com/pleaseappfr" sx={styles.socialIconContainer}>
            <InstagramIcon fontSize="large" sx={styles.socialIcon} />
          </Link>
          <Link
            target="_blank"
            href="https://www.linkedin.com/company/please-international-network"
            sx={styles.socialIconContainer}
          >
            <LinkedInIcon fontSize="large" sx={styles.socialIcon} />
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default FooterComponent;