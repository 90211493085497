import { styled } from '@mui/system';
import themesConfig from '../../../config/themes.config';
import { theme } from '../../../themes';

export const Logo = styled('img')`
  height: 50px;
  image-rendering: -webkit-optimize-contrast;
`;

export const styles: any = {
  modalAddressContainer: {
    position: 'absolute',
    top: '50%',
    borderRadius: '6px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  containerTitle: {
    marginTop: '12px',
    alignItems: 'center',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  containerLabel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: '12px',
  },
  containerContent: {
    marginTop: 12,
    height: 400,
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  contentAddress: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '100%',
  },
  buttonLabel: {
    backgroundColor: '#efefef',
    marginRight: '6px',
    flex: 1,
    display: 'flex',
    borderRadius: '50px',
    color: '#494949',
    height: '42px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  textButton: {
    padding: 0,
    fontSize: '13px',
    fontWeight: 'bold',
  },
  buttonClose: {
    display: 'flex',
    flex: 1,
    marginTop: '12px',
    padding: '12px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  textButtonClose: {
    color: '#fff',
  },
  buttonClicked: {
    backgroundColor: themesConfig.APP_YELLOW,
  },
  containerAdresseUser: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flex: 1,
    display: 'flex',
    backgroundColor: '#efefef',
    borderRadius: '4px',
    padding: '12px',
    marginBottom: '12px',
  },
  joinButton: {
    borderRadius: '4px',
    border: `2px solid ${themesConfig.APP_YELLOW}`,
    padding: 1.5,
    zIndex: 5,
    height: '100%',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleAdress: {
    textAlign: 'left',
    fontSize: '17px',
    fontWeight: 'bold',
  },
  subTitleAdress: {
    textAlign: 'left',
    fontSize: '11px',
    fontWeight: '300',
  },
  streetAdress: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '400',
  },
  containerAdresseUserAdd: {
    flexDirection: 'column',
    flex: 1,
    display: 'flex',
    border: '2px solid #efefef',
    borderRadius: '4px',
    padding: '12px',
    marginBottom: '12px',
  },
  titleAdressAdd: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};
