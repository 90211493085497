/**
 * Container app for module product options
 * Choose an options before add product in cart
 */

// React import
import capitalize from 'lodash/capitalize';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// My script
import {
  sendConfirmCodeSms,
  sendSmsValidatePhoneNumber,
} from '../../../../api/api.phone.number.request';
import { EStatusCode } from '../../../../utils/types';
import PhoneNumberVerification from '../components/phone.number.verification.screen.component';
import { IProps, IPropsSettings } from '../types';

const PhonerNumberVerificationContainer = memo<IProps>(
  ({ confirmValidatePhone, phoneNumber }) => {
    const { t } = useTranslation('M_PHONE_NUMBER_CONFIRM_CODE');

    const dispatch = useDispatch();
    const [codeNumber, setCodeNumber] = useState<string>('');
    const [error, setError] = useState<Array<any>>([]);
    const [isLoad, setLoad] = useState(false);

    // user re send sms code if not received
    async function reSendCodeSms(): Promise<void> {
      try {
        setLoad(true);
        await sendSmsValidatePhoneNumber({
          dispatch,
          mobileNumber: phoneNumber,
        });
        setLoad(false);
      } catch (error) {
        return;
      }
    }

    // on user submit form input
    async function submitForm(): Promise<void> {
      try {
        // Check error input form
        if (!codeNumber || codeNumber.toString().length < 4) {
          // Show error in component
          setError([
            {
              text: capitalize(t('ERROR_CODE_INPUT')),
              id: 0,
            },
          ]);
          return; // stop any child action
        } else setError([]); // reset error empty if not error

        // If not error sending action server
        setLoad(true);
        // send sms user to confirm phone numner
        const result = await sendConfirmCodeSms({ dispatch, codeNumber });

        // if code is validate save mobileNumberValid = true; in user json.
        if (result?.status === EStatusCode.success && result?.json === true) {
          setLoad(false);

          confirmValidatePhone();
        } else {
          setLoad(false);

          // Show error
          setError([
            {
              text: capitalize(t('ERROR_INVALIDATE_CODE')),
              id: 0,
            },
          ]);
        }
      } catch (error) {
        return;
      }
    }

    // On user taping in keyboard input phoneNumber
    function onChangePhoneNumber(number: string): void {
      setError([]);
      setCodeNumber(number);
    }

    // Action to remove an error item
    function onActionError(id: number): void {
      setError((error: any) => error.filter((item: any) => item.id !== id));
    }

    const propsSettings: IPropsSettings = {
      title: t('TITLE_VIEW'), // Icon or image path
      codeNumber,
      setCodeNumber: onChangePhoneNumber,
      error,
      submitForm,
      isLoad,
      phoneNumber,
      onAction: onActionError,
      reSendCodeSms,
    };

    return <PhoneNumberVerification {...propsSettings} />;
  }
);

PhonerNumberVerificationContainer.displayName = 'PhonerNumberVerificationContainer';

export default PhonerNumberVerificationContainer;
