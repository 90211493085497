import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Modal,
  Skeleton,
  Typography,
} from '@mui/material';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import custom
import {
  getContractOfferEnterprise,
  setDefaultDeliveryPro,
} from '../../../api/api.entreprise.offer';
import { getUserBillingAddress } from '../../../api/api.user.billing.address.request';
import { setDefaultDelivery } from '../../../api/api.user.delivery.address.request';
import { getIfAuthUser } from '../../../api/auth.request';
import {
  checkCartValidConciergery,
  getBoutiqueIdOfCart,
  removeCart,
} from '../../../api/cart.product.user.api';
import enterpriseOfferImage from '../../../assets/fond_offre_entreprise_added.png';
import themesConfig from '../../../config/themes.config';
import { RootState } from '../../../store/store';
import { EStatusCode } from '../../../utils/types';
import { styles } from './styles.modal.adress';

const ModalAdressComponent = (props: any) => {
  const dispatch = useDispatch();
  const [toggleItem, setToggleItem] = useState(false);
  const [listAdresseUser, setListAdressUser] = useState(['', '', '', '']);
  const [benefitContract, setBenefitContract] = useState<any>();
  const [isLoad, setLoad] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [addressIdRef, setAddressIdRef] = useState(0);
  const { t } = useTranslation('C_SUB_BAR_HEADER');
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);

  // on user clic close modal adress bar navigation
  function closeModal() {
    props.setShowAdressBar(false);
  }

  // loading adress
  async function init() {
    window.scrollTo(0, 0);
    // check if user auth
    setLoad(true);
    const isAuth = await getIfAuthUser();

    // if user auth get adress of user
    if (isAuth) {
      const getAddressesParams = {
        dispatch: dispatch,
        user_id: user.id?.toString() || '',
      };

      const getContractOfferEnterpriseParams = {
        dispatch: dispatch,
        mw_id: user.id?.toString() || '',
      };

      const [addresses, benefitContract] = await Promise.all([
        getUserBillingAddress(getAddressesParams),
        getContractOfferEnterprise(getContractOfferEnterpriseParams),
      ]);

      if (addresses?.status === EStatusCode.success && addresses?.json?.content) {
        // check default delivery user address perso
        setListAdressUser(
          addresses.json.content.sort((a: any, b: any) => {
            return b.defaultDelivery - a.defaultDelivery;
          })
        );
      }

      setBenefitContract(head(benefitContract?.json?.data) || {});
    }
    setLoad(false);
  }

  // change default adress
  async function updateDefaultAddressUser(overideForceReset: boolean, addressId: number) {
    // change default address delivery
    await setDefaultDelivery({
      dispatch: dispatch,
      address_id: addressId.toString(),
      user_id: user.id?.toString() || '',
    });
    setLoad(false);
    closeModal();

    if (overideForceReset || props.forceRefresh) {
      navigate('/');
    }
  }

  async function updateDefaultAddressUserPro(
    overideForceReset: boolean,
    addressId: number
  ) {
    // change default pro address delivery
    await setDefaultDeliveryPro({
      dispatch: dispatch,
      mw_address_id: addressId,
      odoo_conciergerie_id: 26,
      mw_id: user.id?.toString() || '',
    });
    setLoad(false);
    closeModal();

    if (overideForceReset || props.forceRefresh) {
      navigate('/');
    }
  }

  // user change address in list
  async function onUserSelectedAddress(addressId: number, isAddressPro?: boolean) {
    try {
      setLoad(true);
      /**
       *
       * CHECK IF USER CAN RESET CART BEFORE CHANGE ADDRESS
       *
       */

      // get data cart builded
      const boutiqueCart = await getBoutiqueIdOfCart();

      if (
        boutiqueCart === null ||
        (boutiqueCart !== null && boutiqueCart.articles.length === 0)
      ) {
        const overideForceReset = false;

        if (isAddressPro) {
          updateDefaultAddressUserPro(overideForceReset, addressId);
        } else {
          updateDefaultAddressUser(overideForceReset, addressId);
        }
      } else {
        // check before user change address cart is conciergery valid and if ok show user confirm
        // check if user selected adresse pro or not
        const isEligble = await checkCartValidConciergery({
          dispatch: dispatch,
          serviceId: boutiqueCart?.serviceId,
          addressId: addressId.toString(),
        });

        if (!isEligble?.hasError && isEligble?.json?.data?.isServiceEligible === true) {
          // if offer is eligible dont remove cart

          const overideForceReset = false;

          if (isAddressPro) {
            updateDefaultAddressUserPro(overideForceReset, addressId);
          } else {
            updateDefaultAddressUser(overideForceReset, addressId);
          }
        } else {
          // setOpenModal(false);
          setLoad(false);
          setAddressIdRef(addressId);
          setIsOpenModal(true);
        }
      }
    } catch (error) {}
  }

  function closeDialog() {
    setLoad(false);
    setIsOpenModal(false);
  }

  // confirm to remove cart before change default address
  async function confirmRemoveCart() {
    setIsOpenModal(false);
    await removeCart(dispatch);
    setLoad(true);
    const overideForceReset = true;

    updateDefaultAddressUser(overideForceReset, addressIdRef);
  }

  const clickJoinOffer = () => {
    navigate('/mon-offre-entreprise');
  };

  useEffect(() => {
    if (props.showAdressBar) {
      init();
    }
  }, [props.showAdressBar]);

  return (
    <Box>
      <Modal
        open={props.showAdressBar}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalAddressContainer}>
          <div style={styles.containerTitle}>
            <Typography sx={styles.title}>{t('TITLE_MODAL')}</Typography>
          </div>
          {/** TABS BAR ITEM */}
          <div style={styles.containerLabel}>
            <Button
              onClick={() => {
                setToggleItem(false);
              }}
              sx={[styles.buttonLabel, !toggleItem ? styles.buttonClicked : {}]}
            >
              <Typography sx={styles.textButton}>{t('TITLE_ONGLET_ADDRESS')}</Typography>
            </Button>
            <Button
              onClick={() => {
                setToggleItem(true);
              }}
              sx={[styles.buttonLabel, toggleItem ? styles.buttonClicked : {}]}
            >
              <Typography sx={styles.textButton}>
                {t('TITLE_ONGLET_ADDRESS_PRO')}
              </Typography>
            </Button>
          </div>
          <div style={styles.containerContent}>
            {isLoad && !toggleItem && (
              <Box>
                {[1, 1, 1, 1].map((_, index: number) => {
                  return (
                    <Skeleton
                      key={index}
                      animation="wave"
                      variant="rectangular"
                      width={'100%'}
                      style={{ borderRadius: '4px', marginBottom: '12px' }}
                      height={76}
                    />
                  );
                })}
              </Box>
            )}
            {/** Show adress user */}
            {!isLoad && !toggleItem && (
              <Box sx={styles.contentAddress}>
                {listAdresseUser.map((index: any, key) => {
                  return (
                    <Button
                      key={key}
                      onClick={() => onUserSelectedAddress(index?.address?.id)}
                      sx={{
                        ...styles.containerAdresseUser,
                        ...{
                          border: index.defaultDelivery
                            ? `2px solid ${themesConfig.APP_YELLOW}`
                            : 'transparent',
                          backgroundColor: index.defaultDelivery ? '#fff' : '#efefef',
                        },
                      }}
                    >
                      <Typography variant="h4">{index.address.name}</Typography>
                      <Typography sx={{ textTransform: 'none' }}>
                        {index.address.street}
                      </Typography>
                    </Button>
                  );
                })}
                <Button
                  onClick={props.handleAddAddress}
                  sx={styles.containerAdresseUserAdd}
                >
                  <Typography sx={styles.titleAdressAdd}>
                    {t('ADD_NEW_ADRESSE_BUTTON')}
                  </Typography>
                </Button>
              </Box>
            )}
            {toggleItem && isEmpty(benefitContract) && (
              <Grid container direction="column" sx={styles.contentAddress}>
                <Grid item>
                  <img style={styles.image} src={enterpriseOfferImage} />
                </Grid>
                <Grid item sx={styles.joinButton} onClick={clickJoinOffer}>
                  <Typography textAlign="center" color="primary" fontWeight="600">
                    Rejoindre mon offre entreprise
                  </Typography>
                </Grid>
              </Grid>
            )}
            {/** Show adress enterprise user */}
            {toggleItem && !isEmpty(benefitContract) && (
              <Box sx={styles.contentAddress}>
                <Typography variant="h4" align="center">
                  {t('WELCOME_ENTERPRISE')}
                </Typography>
                <Typography align="center" sx={{ marginBottom: '10px' }}>
                  {t('CUSTOM_MESSAGE_OFFER_PRO')}
                </Typography>
                {benefitContract?.benefitContractAddresses?.map(
                  (index: any, key: number) => {
                    return (
                      <Button
                        key={key}
                        onClick={() =>
                          onUserSelectedAddress(index?.address_details?.id, true)
                        }
                        sx={{
                          ...styles.containerAdresseUser,
                          ...{
                            border: index.defaultDelivery
                              ? `2px solid ${themesConfig.APP_YELLOW}`
                              : 'transparent',
                            backgroundColor: index.defaultDelivery ? '#fff' : '#efefef',
                          },
                        }}
                      >
                        <Typography variant="h4">{index.display_name}</Typography>
                        <Typography sx={{ textTransform: 'none' }}>
                          {index.address_details.street}
                        </Typography>
                      </Button>
                    );
                  }
                )}
              </Box>
            )}
          </div>
          <Button sx={[styles.buttonClose]} onClick={closeModal}>
            <Typography sx={styles.textButtonClose}>{t('BUTTON_CLOSE_MODAL')}</Typography>
          </Button>
        </Box>
      </Modal>
      <Dialog
        open={isOpenModal}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('MODAL_TITLE_CONFIRM_CHANGE')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('MODAL_DESCRIPTION_CONFIRM_CHANGE')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} autoFocus>
            {t('MODAL_BUTTON_CANCEL_CHANGE')}
          </Button>
          <Button onClick={confirmRemoveCart} autoFocus>
            {t('MODAL_BUTTON_CONFIRM_CHANGE')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ModalAdressComponent;
