import { AppBar, Drawer, Grid, IconButton, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';

// custom import
import React from 'react';
import themesConfig from '../../../config/themes.config';
import AdressBannerComponent from '../../adress-banner-user/containers/address.banner.container';
import SearchOffer from '../../search-offer/container/search.offer.container';
import SideMenu from '../../side-menu/container/side-menu.container';
import { CartButton } from './cartButton.components';
import { Logo, styles } from './styles';

const HeaderComponent = (props: any) => {
  const {
    isAuthenticated,
    openDrawer,
    handleDrawerToggle,
    showAddressBar,
    showButtons,
    showSearchBar,
  } = props;

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        sx={[
          styles.appBar,
          showSearchBar
            ? {
                marginBottom: {
                  xs: '0px',
                  md: '70px',
                },
              }
            : {},
        ]}
        id="header-page"
      >
        <Toolbar>
          <Grid container sx={styles.appBarContent} spacing={{ xs: 1, md: 10, lg: 20 }}>
            <Grid item>
              <Link to="/">
                <Logo src={themesConfig.LOGO_APP_WHITE} />
              </Link>
            </Grid>
            <Grid
              item
              sx={styles.addressBanner}
              display={showAddressBar ? 'flex' : 'none'}
            >
              <AdressBannerComponent />
            </Grid>
            {isAuthenticated && showButtons && (
              <Grid
                item
                container
                alignItems="center"
                spacing={1}
                sx={styles.buttonsContainer}
              >
                <Grid item>
                  <CartButton />
                </Grid>
                <Grid item>
                  <IconButton
                    sx={styles.profileButton}
                    onClick={() => handleDrawerToggle()}
                  >
                    <MenuIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      {showSearchBar && <SearchOffer />}

      <Drawer
        anchor="right"
        variant="temporary"
        open={openDrawer}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={styles.drawer}
      >
        <SideMenu />
      </Drawer>
    </React.Fragment>
  );
};

export default HeaderComponent;
