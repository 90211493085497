import { useNavigate } from 'react-router';

// my custom import
import SearchOfferComponent from '../component/search.offer.components';
import { IAroundMeComponentProps } from '../types';

const SearchOfferContainer = () => {
  const navigate = useNavigate();

  function navigateSearch(search: string) {
    navigate({
      pathname: 'recherche',
      search: `?q=${search}`,
    });
  }

  const aroundMeComponentProps: IAroundMeComponentProps = {
    navigateSearch,
  };

  return <SearchOfferComponent {...aroundMeComponentProps} />;
};

export default SearchOfferContainer;
