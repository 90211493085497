import { CircularProgress, Grid } from '@mui/material';
import FooterContainer from '../../footer/containers/footer.containers';
import HeaderContainer from '../../header/containers/header.containers';
import { styles } from './styles';

const LoadingComponent = () => {
  return (
    <Grid container direction="column" sx={styles.pageContainer}>
      <HeaderContainer showAddressBar={false} showButtons={false} />
      <Grid
        item
        container
        sx={styles.pageContainer}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
      <FooterContainer />
    </Grid>
  );
};

export default LoadingComponent;
