import { styled } from '@mui/system';
import config from '../../../config/themes.config';
import { theme } from '../../../themes';

export const Logo = styled('img')`
  height: 50px;
  image-rendering: -webkit-optimize-contrast;
`;

export const styles: any = {
  container: {
    display: {
      xs: 'none',
      md: 'flex',
    },
    flex: 1,
    position: 'fixed',
    zIndex: 1000,
    pading: '4px',
    width: '100%',
    top: { xs: '125px', md: '74px' },
    background: '#fff',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  containerInput: {
    display: 'flex',
    flex: 1,
    height: '60px',
    marginLeft: '6px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconSearch: {
    fontSize: '34px',
    color: '#494949',
    marginRight: '12px',
  },
  textInput: {
    color: '#494949',
    marginLeft: '12px',
  },
  containerTextHello: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '450px',
  },
  containerSearchModal: {
    backgroundColor: '#efefef',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    padding: '8px',
  },
  modalContent: {
    height: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
  },
  ContentModalContainer: {
    flex: 1,
    width: '100%',
  },
  content: {
    backgroundColor: '#fff',
    margin: '12px',
    maxHeight: '550px',
    borderRadius: '4px',
    padding: '12px',
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  closeModal: {
    padding: '12px',
    marginTop: '12px',
    backgroundColor: config.APP_YELLOW,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
};
