import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { CreditCard } from '../models';
import { TCreditCardUpdates } from '../api/types';
import { AlertContextProvider } from './alert-context';
import { ConfirmContextProvider } from './confirm-context';

export interface CreditCardStateProps {
  creditCards: CreditCard[];
  mainCreditCard: CreditCard | null;
  totalPrice?: number;
  creditCardLoading?: boolean;
  isPaymentProcessing?: boolean;
  isPaymentDisabled?: boolean;
  updateCreditCard?: (
    creditCard: CreditCard | null,
    updates: TCreditCardUpdates
  ) => Promise<void>;
  removeCreditCard?: (creditCard: CreditCard) => Promise<void>;
  processPayment?: (useMainCreditCard?: boolean) => Promise<void>;
}

export interface CreditCardContextProps {
  state: CreditCardStateProps;
  act: Dispatch<PayloadAction<any, CreditCardContextAction>>;
}

const creditCardContextDefaults: CreditCardContextProps = {
  state: {
    creditCards: [],
    mainCreditCard: null,
  },
  act: () => void 0,
};

export const CreditCardContext = createContext<CreditCardContextProps>(
  creditCardContextDefaults
);

export enum CreditCardContextAction {
  SET_CREDIT_CARDS = 'SET_CREDIT_CARDS',
  SET_MAIN_CREDIT_CARD = 'SET_MAIN_CREDIT_CARD',
  SET_TOTAL_PRICE = 'SET_TOTAL_PRICE',
  SET_PAYMENT_DISABLED = 'SET_PAYMENT_DISABLED',
  SET_PAYMENT_PROCESSING = 'SET_PAYMENT_PROCESSING',
  CREDIT_CARD_LOADING = 'CREDIT_CARD_LOADING',
}

/**
 * @description Credit card reducer
 */
function creditCardReducer(
  state = creditCardContextDefaults.state,
  action: PayloadAction<any, CreditCardContextAction>
): CreditCardStateProps {
  switch (action.type) {
    case CreditCardContextAction.SET_CREDIT_CARDS:
      return { ...state, creditCards: action.payload };
    case CreditCardContextAction.SET_MAIN_CREDIT_CARD:
      return { ...state, mainCreditCard: action.payload };
    case CreditCardContextAction.SET_TOTAL_PRICE:
      return { ...state, totalPrice: action.payload };
    case CreditCardContextAction.SET_PAYMENT_DISABLED:
      return { ...state, isPaymentDisabled: action.payload };
    case CreditCardContextAction.SET_PAYMENT_PROCESSING:
      return { ...state, isPaymentProcessing: action.payload };
    case CreditCardContextAction.CREDIT_CARD_LOADING:
      return { ...state, creditCardLoading: action.payload };
    default:
      return state;
  }
}

export const CreditCardContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(
    creditCardReducer,
    creditCardContextDefaults.state
  );

  return (
    <CreditCardContext.Provider
      value={{
        state,
        act: dispatch,
      }}
    >
      <AlertContextProvider>
        <ConfirmContextProvider>{children}</ConfirmContextProvider>
      </AlertContextProvider>
    </CreditCardContext.Provider>
  );
};
