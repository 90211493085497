/**
 * Export default indicatif of phone_number
 */

// My script
import { IConfigNumber, IPhoneNumber } from './types';

const IDCF_PHONE_NUMBER: IPhoneNumber = ['+262', '+33', '+590', '+596', '+594'];

const themesConfig: IConfigNumber = {
  IDCF_PHONE_NUMBER,
};

export default themesConfig;
