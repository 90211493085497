/**
 * Layout address to edit or add new addresse form details user delivery
 */

// React import
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockIcon from '@mui/icons-material/Lock';
import SecurityIcon from '@mui/icons-material/Security';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// My script
import { logout } from '../../../api/auth.request';
import { RootState } from '../../../store/store';
import SideMenuComponent from '../components/side-menu.components';
import { EListNavigation, IPropsData } from '../types';

const SideMenuContainer = () => {
  const [showModalLogout, setShowModalLogout] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation('M_ACCOUNT_SCREEN');
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);

  // on user click navigate in specific page
  const handleClick = (item: any) => {
    navigate(item.url);
  };

  // confirm user logout
  function handleConfirmLogout() {
    setShowModalLogout(false);
    logout();
    navigate('/');
    window.location.reload();
  }

  // cancel click modal user logout
  function handleCancelLogout() {
    setShowModalLogout(false);
  }

  // create list of all route navigation bar
  const menuList = [
    {
      name: t('NAVIGATION_USER_INFO'),
      icon: <AccountCircleIcon />,
      id: EListNavigation.ACCOUNT_USER,
      url: '/mes-infos-personnelles',
    },
    {
      name: t('NAVIGATION_ADDRESS_DELIVERY'),
      icon: <AddLocationIcon />,
      id: EListNavigation.ADRESSSE_DELIVERY,
      url: '/addresse-de-livraison',
    },
    {
      name: t('NAVIGATION_REWARD'),
      icon: <CardGiftcardIcon />,
      id: EListNavigation.MY_REWARD,
      url: '/mes-coupons',
    },
    {
      name: t('NAVIGATION_SETTING_PAYMENT_METHOD'),
      icon: <CreditCardIcon />,
      id: EListNavigation.CART_PAYMENT,
      url: '/mes-moyens-de-paiements',
    },
    {
      name: t('NAVIGATION_UPDATE_PASSWORD'),
      icon: <LockIcon />,
      id: EListNavigation.UPDATE_PASSWORD,
      url: '/modifier-mon-mot-de-passe',
    },
    {
      name: t('NAVIGATION_OFFER_PRO'),
      icon: <BusinessCenterIcon />,
      id: EListNavigation.ENTERPRISE_OFFER,
      url: '/mon-offre-entreprise',
    },
    {
      name: t('NAVIGATION_CONTACT'),
      icon: <ContactPhoneIcon />,
      id: EListNavigation.CONTACT,
      url: '/contact',
    },
    {
      name: t('NAVIGATION_PRIVATE_LIFE'),
      icon: <SecurityIcon />,
      id: EListNavigation.PRIVACY,
      url: '/vie-privee',
    },
  ];

  // init params and render view item
  function renderView() {
    const dataProps: IPropsData = {
      menuList,
      handleConfirmLogout,
      handleCancelLogout,
      handleClick,
      showModalLogout,
      setShowModalLogout,
      userInfo: user,
      showModal,
      setShowModal,
    };

    return <SideMenuComponent {...dataProps} />;
  }

  return renderView();
};

export default SideMenuContainer;
