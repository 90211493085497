/**
 * Definitions of all Ip address server
 */

export enum EConfigEnvironement {
  prod = 'production',
  preProd = 'staging',
  dev = 'development',
  mockup = 'mockup', // hors ligne disable request
}

export interface IEnvironmentConfig {
  [index: string]: IEnvironmentConfigData;
}

export interface IEnvironmentConfigData {
  endPoint: string;
  endPointTranslation: string;
  overideEndPoint: string;
  maxRetry: number;
  endPointImage: string;
  webSocketDomain: string;
  nodeApiUrl: string;
}

const EnvironmentConfig: IEnvironmentConfig = {};

EnvironmentConfig[EConfigEnvironement.prod] = {
  endPoint: 'https://mw.please-it.com/next-mw/',
  endPointTranslation: 'https://mw.please-it.com/',
  overideEndPoint: 'https://mw.please-it.com/',
  endPointImage: 'https://mw.please-it.com/',
  webSocketDomain: 'mw.please-it.com',
  nodeApiUrl: 'http://localhost:4000/api',
  maxRetry: 5,
};

EnvironmentConfig[EConfigEnvironement.preProd] = {
  endPoint: 'https://staging-api.pleaseapp.com/next-mw/',
  endPointTranslation: 'https://staging-api.pleaseapp.com/',
  overideEndPoint: 'https://staging-api.pleaseapp.com/',
  endPointImage: 'https://staging-api.pleaseapp.com/',
  webSocketDomain: 'staging-api.pleaseapp.com',
  nodeApiUrl: 'http://localhost:4000/api',
  maxRetry: 5,
};

EnvironmentConfig[EConfigEnvironement.dev] = {
  endPoint: 'https://dev-api.pleaseapp.com/next-mw/',
  endPointTranslation: 'https://dev-api.pleaseapp.com/',
  overideEndPoint: 'https://dev-api.pleaseapp.com/',
  endPointImage: 'https://dev-api.pleaseapp.com/',
  webSocketDomain: 'dev-api.pleaseapp.com',
  nodeApiUrl: 'http://localhost:4000/api',
  maxRetry: 5,
};

export default EnvironmentConfig;
