import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Button, Container, Typography } from '@mui/material';
import React from 'react';

// import custom
import { IAddressBannerComponentProps } from '../types';
import ModalAdressComponent from './modal.adress.components';
import { styles } from './styles';

const AdressBannerComponent = (props: IAddressBannerComponentProps) => {
  const { showAdressBar, setShowAdressBar, onClickAdressBar, handleAddAddress, user } =
    props;

  return (
    <React.Fragment>
      <Container sx={styles.containerAddress} maxWidth={false}>
        <Button onClick={() => onClickAdressBar()} sx={styles.addressBarButton}>
          <LocationOnIcon color="secondary" sx={styles.locationIcon} />
          <div style={styles.containerAddressName}>
            <Typography sx={styles.addressName}>
              {user.defaultDeliveryAddress?.name}
            </Typography>
            <Typography sx={styles.address}>
              {user.defaultDeliveryAddress?.street}
            </Typography>
          </div>
          <KeyboardArrowDownIcon color="secondary" />
        </Button>
      </Container>

      <ModalAdressComponent
        showAdressBar={showAdressBar}
        setShowAdressBar={setShowAdressBar}
        handleAddAddress={handleAddAddress}
      />
    </React.Fragment>
  );
};

export default AdressBannerComponent;
