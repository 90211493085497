/**
 * GET ENVIRONEMENT CONFIG APP
 */

import EnvironmentConfig, {
  EConfigEnvironement,
  IEnvironmentConfigData,
} from '../config/api.config';

// Get environement config
export function getConfigEnvironement(): IEnvironmentConfigData {
  switch (process.env.REACT_APP_ENV) {
    case EConfigEnvironement.preProd:
      return EnvironmentConfig[EConfigEnvironement.preProd];
    case EConfigEnvironement.dev:
      return EnvironmentConfig[EConfigEnvironement.dev];
    case EConfigEnvironement.prod:
      return EnvironmentConfig[EConfigEnvironement.prod];
    default:
      return EnvironmentConfig[process.env.NODE_ENV];
  }
}
