import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';
import { ManagerOptions, Socket, SocketOptions, io } from 'socket.io-client';
import { getUserInfo } from '../api/auth.request';
import { IPropsUserAuth } from '../api/types';

export type SocketEventMapType = { [key: string]: (...args: any[]) => void };

export type SocketType<
  ServerEvents extends SocketEventMapType = any,
  ClientEvents extends SocketEventMapType = any
> = Socket<ServerEvents, ClientEvents>;

export const SocketContext = createContext<SocketType | null>(null);

export interface SocketContextProviderProps extends PropsWithChildren {
  socketDomain: string;
  socketNamespace: string;
  socketOptions: Partial<ManagerOptions & SocketOptions> &
    Required<Pick<ManagerOptions, 'path'>>;
}

export const SocketContextProvider: FC<SocketContextProviderProps> = ({
  children,
  socketDomain,
  socketNamespace,
  socketOptions,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [authUser, setAuthUser] = useState<IPropsUserAuth | null>(null);

  useEffect(() => {
    async function getAuthUser() {
      const authUser = getUserInfo();

      setAuthUser(authUser);
    }

    getAuthUser();
  }, []);

  useEffect(() => {
    if (!authUser) return;

    const config: Partial<ManagerOptions & SocketOptions> = {
      reconnectionDelayMax: 10000,
      autoConnect: false,
      transports: ['websocket'],
      auth: { token: `Bearer ${authUser.accessToken}` },
      ...socketOptions,
    };

    const s: Socket = io(`wss://${socketDomain}/${socketNamespace}`, config);

    setSocket(s);
  }, [authUser]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
