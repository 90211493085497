import { Box, Button, Dialog, DialogActions, DialogTitle, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';

// My script
import PhoneNumberConfirmCode from '../../phone.number.confirm.code/containers/phone.number.verification.screen.containers';
import { IPropsSettings } from '../types';
import PhoneNumberFormComponent from './phone.number.form.component';
import { styles } from './styles';
const PhoneNumberFormScreen = (props: IPropsSettings) => {
  const { t } = useTranslation('M_PHONE_NUMBER_FORM');

  const { phoneNumber, showModal, handleClose, levelPage } = props;

  function confirmValidatePhone() {
    props.setIsOpenConfirmSms(true);
  }

  const params = {
    phoneNumber,
    confirmValidatePhone,
  };

  return (
    <Modal
      sx={styles.modalContent}
      open={showModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        {levelPage === 0 && <PhoneNumberFormComponent {...props} />}
        {levelPage === 1 && <PhoneNumberConfirmCode {...params} />}

        {/** show dialog confirm phonenumber validate */}
        <Dialog
          open={props.isOpenConfirmSms}
          onClose={props.handleCloseOpenConfirmSms}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('TITLE_MODAL_CONFIRM_SMS_VALIDATE')}
          </DialogTitle>
          <DialogActions>
            <Button onClick={props.handleCloseOpenConfirmSms} autoFocus>
              {t('BUTTON_CONFIRM_MODAL_SMS_VALIDATE')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
};

export default PhoneNumberFormScreen;
