/**
 * Container app for user valide phone number
 */

// React import
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// My script
import {
  phoneNumberExist,
  sendSmsValidatePhoneNumber,
} from '../../../../api/api.phone.number.request';
import configPhoneNumber from '../../../../config/indicatif.phone.number.config';
import regexConfig from '../../../../config/regex.form';
import { RootState } from '../../../../store/store';
import { EStatusCode } from '../../../../utils/types';
import PhoneNumberFormScreen from '../components/phone.number.form.screen.component';
import { IProps, IPropsSettings } from '../types';

const PhonerNumberFormContainer = memo<IProps>((props: IProps) => {
  const dispatch = useDispatch();
  const [indicatifPhoneNumber, setIndicatifPhoneNumber] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState<Array<any>>([]);
  const [isLoad, setLoad] = useState(false);
  const { showModal, setShowModal } = props.data;
  const [levelPage, setLevelPage] = useState(0);
  const [isOpenConfirmSms, setIsOpenConfirmSms] = useState(false);
  const { t } = useTranslation('M_PHONE_NUMBER_FORM');

  const user = useSelector((state: RootState) => state.user);

  async function init(): Promise<void> {
    try {
      setLoad(true);

      // get phonenumber user
      const mobileNumber = user.mobileNumber || '';

      // set default phone number
      setPhoneNumber(`0${mobileNumber.slice(-9)}`);

      // Get indicatif user selected and update input form
      // retrieve in data indicatif user
      const indicatif = mobileNumber.slice(0, -9);
      // get index of config all indicatif form
      const indexIndicatif = configPhoneNumber.IDCF_PHONE_NUMBER.findIndex(elm => {
        return elm === indicatif;
      });

      // if isset indicatif update value indicatif
      if (indexIndicatif !== -1) {
        onValueChange(indexIndicatif.toString());
      }
      setLoad(false);
    } catch (error) {
      // dispatch event error
    }
  }

  // On enter screen
  useEffect(() => {
    setLevelPage(0);

    // refresh call if user open modal
    if (showModal === true) {
      init();
    }
  }, [showModal, user]);

  // ON change user change indicatif number value
  function onValueChange(indcatif: string): void {
    setIndicatifPhoneNumber(parseInt(indcatif));
  }

  // on user submit form input
  async function submitForm(): Promise<void> {
    try {
      // setLoad(true);

      const regex = new RegExp(regexConfig.PHONE_NUMBER);

      // Check error input form
      if (!regex.test(phoneNumber)) {
        const newDataError: Array<any> = [];

        // Convert to an Array<Object>
        newDataError.push({
          text: t('MESSAGE_INPUT_FORM_ERROR_CONF_PHONE_NUMBER_FORMAT'),
          id: 0,
        });

        // Show error in component
        setError(newDataError);
        return; // stop any child action
      } else setError([]); // reset error empty if not error

      // If not error sending action server
      setLoad(true);

      // create formatted phone Number for odoo
      const formattedNumberPhone = `${
        configPhoneNumber.IDCF_PHONE_NUMBER[indicatifPhoneNumber]
      }${phoneNumber.slice(1)}`;

      // CHECK BEFORE IF PHONE_NUMBER NOT EXIST
      const issetPhone = await phoneNumberExist({
        dispatch,
        mobileNumber: formattedNumberPhone,
      });

      // if phone not exist save new phone number
      if (issetPhone?.status === EStatusCode.success && issetPhone?.json === false) {
        // send sms user to confirm phone numner
        const response = await sendSmsValidatePhoneNumber({
          dispatch,
          mobileNumber: formattedNumberPhone,
        });

        if (response?.hasError && response?.data?.statusCode === 429) {
          setLoad(false);
          setError([{ text: t('TOO_MANY_REQUEST'), id: 0 }]);
        }

        setLoad(false);

        setLevelPage(1);
      } else {
        // show error user phone not updated because is exist
        setLoad(false);
        setError([{ text: t('ERROR_PHONE_EXIST'), id: 0 }]);
      }
    } catch (error) {
      return;
    }
  }

  // On user taping in keyboard input phoneNumber
  function onChangePhoneNumber(number: string): void {
    setError([]);
    setPhoneNumber(number);
  }

  // Action to remove an error item
  function onActionError(id: number): void {
    setError(error => error.filter((item: any) => item.id !== id));
  }

  // on user close modal
  function closeModal(): void {
    setShowModal(false);
  }

  // on user clode modal confirm phoneNumber verifier
  function handleCloseOpenConfirmSms(): void {
    setIsOpenConfirmSms(false);
    closeModal();
  }

  // Passing parameter to view
  const propsSettings: IPropsSettings = {
    title: t('TITLE_VIEW'), // Icon or image path
    indicatifPhoneNumber,
    onValueChange,
    phoneNumber,
    setPhoneNumber: onChangePhoneNumber,
    error,
    configPhoneNumber: configPhoneNumber.IDCF_PHONE_NUMBER,
    submitForm,
    isLoad,
    onAction: onActionError,
    showModal,
    handleClose: closeModal,
    levelPage,
    setLevelPage,
    handleCloseOpenConfirmSms,
    isOpenConfirmSms,
    setIsOpenConfirmSms,
  };

  return <PhoneNumberFormScreen {...propsSettings} />;
});

PhonerNumberFormContainer.displayName = 'PhonerNumberFormContainer';

export default PhonerNumberFormContainer;
