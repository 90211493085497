import { styled } from '@mui/system';
import { theme } from '../../../themes';

export const Logo = styled('img')`
  height: 50px;
  image-rendering: -webkit-optimize-contrast;
`;

export const styles: any = {
  containerAddress: {
    display: 'flex',
    marginLeft: '0px!important',
    paddingLeft: '0px!important',
    paddingRight: '0px!important',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  addressName: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    lineHeight: '1.1rem',
    textAlign: 'left',
  },
  containerAddressName: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    marginLeft: 12,
  },
  locationIcon: {
    marginLeft: '4px',
  },
  addressBarButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.dark,
    },
  },
  address: {
    fontSize: '0.9rem',
    lineHeight: '1.1rem',
    textAlign: 'left',
  },
  addressButton: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '0px',
  },
  containerLoad: {
    height: '35px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
};
