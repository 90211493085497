export const styles: any = {
  container: {
    flex: 1,
    backgroundColor: '#ffebee',
    borderWidth: 1,
    borderColor: '#ef5350',
    borderRadius: '6px',
    marginBottom: '4px',
    flexDirection: 'row',
    padding: '6px',
    display: 'flex',
  },
  containerText: {
    marginLeft: '4px',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: '12px',
    width: '28px',
    height: '28px',
    borderRadius: '25px',
    alignItems: 'center',
    borderWidth: 1,
    display: 'flex',
    borderColor: '#424242',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  text: {
    color: '#424242',
  },
};
