import { Box, Button, Skeleton, TextField, Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';
import split from 'lodash/split';
import toUpper from 'lodash/toUpper';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// My script
import ErrorItems from '../../../../components/error-items/error.items.component';
import { IPropsSettings } from '../types';
import { styles } from './styles';

const PhoneNumberVerificationScreen: FC<IPropsSettings> = ({
  codeNumber,
  phoneNumber,
  setCodeNumber,
  error,
  submitForm,
  isLoad,
  reSendCodeSms,
  onAction,
}) => {
  const { t } = useTranslation('M_PHONE_NUMBER_CONFIRM_CODE');
  const inputRef: any = React.useRef();

  // Get input focus on user clic in fake box
  function inputFocus() {
    // set input on focus show keyboard
    inputRef?.current?.focus();
    // reset input variable
    setCodeNumber('');
  }

  return (
    <Box sx={styles.modalAddressContainer}>
      {isLoad && (
        <Box sx={{ padding: '12px' }}>
          <Skeleton animation="wave" width={'100%'} height={37} />
          <Skeleton animation="wave" variant="rectangular" width={'100%'} height={103} />
          <Skeleton animation="wave" width={'100%'} height={32} />
          <Skeleton animation="wave" width={'100%'} height={21} />
          <Skeleton animation="wave" width={'100%'} height={21} />
        </Box>
      )}

      {!isLoad && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {/**Title and description page */}
          <Typography sx={styles.title}>
            {capitalize(t('DESCRIPTION_TUTO').replace('%{phoneNumber}', phoneNumber))}
          </Typography>

          {/** Layout error */}
          {error.length > 0 && (
            <Box sx={{ margin: '12px', marginTop: 0, flexDirection: 'row' }}>
              {error.map((index, key) => {
                return (
                  <ErrorItems
                    key={key}
                    data={{ text: index.text, action: onAction, id: index.id }}
                  />
                );
              })}
            </Box>
          )}

          {/** Form input container text sms code validation */}
          <Button
            sx={styles.formInput}
            onClick={() => {
              inputFocus();
            }}
            disableRipple
          >
            {/**Fake input box */}
            <Box sx={styles.containerInput}>
              {[0, 1, 2, 3].map(index => {
                return (
                  <Box
                    key={index}
                    sx={{
                      ...styles.input,
                      ...{ marginLeft: index > 0 ? '22px' : 0 },
                    }}
                  >
                    {((index === 0 && codeNumber.length === 0) ||
                      (index === 1 && codeNumber.length === 1) ||
                      (index === 2 && codeNumber.length === 2) ||
                      (index === 3 && codeNumber.length === 3)) && (
                      <Box
                        sx={{
                          backgroundColor: '#efefef',
                          margin: '4px',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          borderRadius: '4px',
                        }}
                      ></Box>
                    )}
                    <Typography>{split(codeNumber, '')[index]}</Typography>
                  </Box>
                );
              })}
            </Box>

            {/**hidden input */}
            <TextField
              inputRef={inputRef}
              id="outlined-name"
              value={codeNumber}
              sx={styles.inputContent}
              inputProps={{ maxLength: 4 }}
              onChange={event => {
                setCodeNumber(event.target.value);
              }}
            />
          </Button>

          {/**Container button of content */}
          <Box sx={styles.containerButton}>
            {/** container buttton re send code not received */}
            <Box sx={styles.containerButtonReSendCode}>
              {/**Text of action user */}
              <Typography sx={{ marginBottom: '22px' }}>
                {capitalize(t('TEXT_CODE_NOT_RECEIVE'))}
              </Typography>

              <Button
                onClick={() => {
                  reSendCodeSms();
                }}
                sx={styles.ButtonReSend}
              >
                <Typography sx={styles.ButtonTitleReSend}>
                  {toUpper(t('BUTTTON_SEND_NEW_CODE'))}
                </Typography>
              </Button>
            </Box>
          </Box>
          {/** container buttton validate code */}
          <Box style={{ margin: '12px', marginTop: 0, flex: 1, display: 'flex' }}>
            <Button
              onClick={() => {
                submitForm();
              }}
              sx={styles.Button}
            >
              <Typography sx={styles.ButtonTitle}>{t('BUTTON_VALIDER')}</Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PhoneNumberVerificationScreen;
