export enum AppRoutePath {
  HOME = '/',
  LOGIN = 'connexion',
  REGISTER = 'inscription',

  OFFER_MENU = 'menu-offre/:serviceId',
  PRODUCT_SELECTION = 'product-selection',

  ORDER_METHOD = 'modalite-de-commande',
  ORDER_PAYMENT = 'modalite-de-paiement',
  USER_ACCOUNT_INFO = 'mes-infos-personnelles',
  DELIVERY_ADDRESS = 'addresse-de-livraison',
  PAYMENT_METHOD = 'mes-moyens-de-paiements',
  PRIVATE_LIFE = 'vie-privee',
  CONTACT = 'contact',

  UPDATE_PASSWORD = 'modifier-mon-mot-de-passe',
  PASSWORD_RESET = 'mot-de-passe/reset/:token',

  VOUCHER = 'mes-coupons',
  SPONSORSHIP = 'parrainage',

  EDIT_DELIVERY_ADDRESS = 'addresse-de-livraison/details',
  ADD_DELIVERY_ADDRESS = 'addresse-de-livraison/ajouter',
  ADD_DELIVERY_ADDRESS_END = 'addresse-de-livraison/ajouter/fin',

  ORDER_HISTORY = 'mes-commandes',
  ORDER_HISTORY_DETAILS = 'mes-commandes/details/:orderId',
  BUSINESS_OFFER = 'mon-offre-entreprise',

  CATEGORY_TAG = 'categorie/tag/:tagName/:tagId',
  CATEGORY_BUSINESS_OFFER = 'categorie/offre-entreprise',
  CATEGORY = 'categorie/:categoryId',

  SEARCH = 'recherche',
  SHOPPING_CART = 'panier',
  PAYMENT_VALIDATION = 'offres/paiement/statut',

  OTHER = '*',
}
