import { styled } from '@mui/system';
import themesConfig from '../../../../config/themes.config';
import { theme } from '../../../../themes';

export const Logo = styled('img')`
  height: 50px;
  image-rendering: -webkit-optimize-contrast;
`;

export const styles: any = {
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '22px',
  },
  modalContent: {
    height: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
  },
  modalAddressContainer: {
    position: 'absolute',
    top: '50%',
    borderRadius: '6px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  load: {
    flex: 1,
  },
  description: {
    fontSize: '12px',
    fontWeight: '300',
    textAlign: 'center',
    marginTop: '12px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  titleInput: {
    marginLeft: '12px',
    fontWeight: '300',
    marginTop: '22px',
    fontSize: '14px',
  },
  subTitle: {
    marginLeft: '12px',
    fontWeight: '300',
    marginTop: '8px',
    fontSize: '14px',
    color: '#424242',
  },
  /**PHone number form */
  containerPhoneNumber: {
    flex: 1,
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    marginLeft: '16px',
    marginRight: '16px',
  },
  noPadding: {
    display: 'flex',
    flex: 1,
  },
  pickerContainer: {
    display: 'flex',
  },
  inputContainer: {
    paddingLeft: '22px',
    flex: 1,
  },
  footerContainer: {
    borderTopWidth: 0,
    display: 'flex',
    backgroundColor: 'transparent',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingTop: 0,
    marginTop: '6px',
    paddingBottom: '12px',
    flexDirection: 'column',
  },
  Button: {
    backgroundColor: themesConfig.APP_YELLOW,
    flex: 1,
    padding: '12px',
    marginBottom: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  ButtonTitle: {
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  buttonPlus: {
    width: '50px',
    display: 'flex',
    height: '50px',
    borderColor: '#e0e0e0',
    borderWidth: '2px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '40px',
  },
};
