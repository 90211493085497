import { AlertColor, SnackbarOrigin } from '@mui/material';
import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';

export enum AlertAction {
  ALERT = 'alert',
  CLOSE = 'close',
}

export interface AlertState {
  anchorOrigin: SnackbarOrigin;
  autoHideDuration: number;
  isOpen: boolean;
  message: string | null;
  severity: AlertColor;
  variant: 'filled' | 'standard' | 'outlined';
}

const defaultAlertState: AlertState = {
  anchorOrigin: { horizontal: 'right', vertical: 'top' },
  autoHideDuration: 5000,
  isOpen: false,
  message: null,
  severity: 'success',
  variant: 'filled',
};

export const AlertContext = createContext<{
  dispatch?: Dispatch<AlertPayloadAction>;
  state: AlertState;
}>({ state: defaultAlertState });

export type AlertPayload = Partial<typeof defaultAlertState>;

export type AlertPayloadAction = {
  type: AlertAction;
  payload?: AlertPayload;
};

/**
 * @description Alert Reducer that have 3 actions (Alert, accept, cancel)
 */
function AlertReducer(state = defaultAlertState, action: AlertPayloadAction): AlertState {
  switch (action.type) {
    case AlertAction.ALERT:
      return { ...state, ...action.payload, isOpen: true };
    case AlertAction.CLOSE:
      return { ...defaultAlertState };
    default:
      return state;
  }
}

/**
 * @description Provider component that provides Alert context
 */
export const AlertContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(AlertReducer, defaultAlertState);

  return (
    <AlertContext.Provider value={{ dispatch, state }}>{children}</AlertContext.Provider>
  );
};
