import { styled } from '@mui/system';

export const Logo = styled('img')`
  height: 50px;
  image-rendering: -webkit-optimize-contrast;
`;

export const styles: any = {
  container: {
    backgroundColor: '#494949',
    padding: 2,
    marginTop: 'auto',
  },
  title: {
    fontSize: 22,
    fontWeight: '800',
    color: '#fff',
    marginBottom: '12px',
  },
  description: {
    fontSize: 16,
    marginTop: '12px',
    fontWeight: '400',
    color: '#efefef',
  },
  socialIconContainer: {
    marginLeft: '22px',
  },
  socialIcon: {
    color: '#fff',
  },
  iconSocial: {
    fontSize: 40,
  },
};
