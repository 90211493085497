/**
 * Configure themes app
 */

// My script
import { IRegex } from './types';

const PHONE_NUMBER = /^[0][0-9](\d{2}){4}$/g;
const PASSWORD = '(?=.*[0-9])(?=.*[a-zA-Z]).+';

const regexConfig: IRegex = {
  PHONE_NUMBER,
  PASSWORD,
};

export default regexConfig;
