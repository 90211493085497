// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Import external libraries
 */

/**
 * Main environment config
 */
export const environment = {
  // Utility method to defined if is a production env and node and angular way
  isProduction: () => {
    // Check if window exists in this case is angular scope otherwise is node otherwise
    return process.env.NODE_ENV == 'production';
  },
  hmr: false,
  // previous version must be set to enable angular hmr or production auto process
  name: 'local',
  endPoint: 'https://mw-preprod.please-it.com/next-mw/',
  endPointTranslation: 'https://mw-preprod.please-it.com/',
  overideEndPoint: 'https://mw-preprod.please-it.com/ms-order/',
  nodeApi: 'http://localhost:4000/api',
  gestionUrl: 'https://staging-gestion.please-it.com/#/dashboard/users/client/',
  odooUrl: 'https://staging-erp.please-it.com/web#id=',

  /**
   * Generic configuration vars
   */
  application: {
    // Default name of web app
    name: 'please-website-v2',
  },
  /**
   * Configuration for node part
   */
  node: {
    allowedExt: [
      'js',
      'ico',
      'css',
      'png',
      'jpg',
      'jpeg',
      'woff2',
      'woff',
      'ttf',
      'svg',
      'txt',
    ],
    distPath: '/usr/src/app',
    exposedPort: 4000,
    // allowedOrigin: () => {
    //   // Return list of allowed origin
    //   return _.flattenDeep([
    //     environment.envKeyByHost.development.hosts,
    //     environment.envKeyByHost.staging.hosts,
    //     environment.envKeyByHost.production.hosts,
    //   ]);
    // },
    apiLocalEndpoint: '/api',
    enableAccessLog: (process: any): boolean => {
      return process?.env.ENABLE_ACCESS_LOG || false;
    },
    // Based on winston level
    // @see https://www.npmjs.com/package/winston#logging-levels
    getLogLevel: () => {
      return environment.isProduction() ? 'info' : 'debug';
    },
  },
  /**
   * Configuration for angular part
   */
  ng: {
    // We need to defined we config for http Client on angular way
    httpClient: {
      headers: {
        currentEnv: {
          name: 'X-App-Environment',
        },
        appOrigin: {
          name: 'X-App-Origin',
          value: 'mob_pub', // TODO change this when middleware will allow new header
        },
        appVersion: {
          name: 'X-App-Version',
        },
        appVersionDevice: {
          name: 'X-App-Version-Device',
        },
        appOS: {
          name: 'X-App-OS',
          value: 'ios',
        },
        authLogin: {
          name: 'X-User-Login',
          value: 'admin@pentalog.fr',
        },
        authToken: {
          name: 'Authorization',
          value: 'accessToken',
        },
        apiMethod: {
          name: 'X-Api-Method',
        },
        apiEndpoint: {
          name: 'X-Api-Endpoint',
        },
        apiAcceptVersion: {
          name: 'X-Accept-Version',
        },
        apiVersion: {
          name: 'X-Api-Version',
        },
        apiExternalHost: {
          name: 'X-Api-External-Host',
        },
      },
    },
  },
};
