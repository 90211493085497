import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store/store';

// my custom import
import AdressBannerComponent from '../components/address.banner.component';

const AdressBannerContainer = () => {
  const [showAdressBar, setShowAdressBar] = useState(false);

  const user = useSelector((state: RootState) => state.user);

  const navigate = useNavigate();

  function onClickAdressBar() {
    setShowAdressBar(true);
  }

  // on click user address address button
  function handleAddAddress() {
    navigate('/addresse-de-livraison/ajouter');
  }

  const addressBannerComponentProps = {
    showAdressBar,
    setShowAdressBar,
    onClickAdressBar,
    handleAddAddress,
    user,
  };

  return <AdressBannerComponent {...addressBannerComponentProps} />;
};

export default AdressBannerContainer;
