import { styled } from '@mui/system';
import themesConfig from '../../../config/themes.config';
import { theme } from '../../../themes';

export const Logo = styled('img')`
  height: 50px;
  image-rendering: -webkit-optimize-contrast;
`;

export const styles: any = {
  // product screen styles
  containerUser: {
    margin: '12px',
    borderRadius: '6px',
    padding: '8px',
    backgroundColor: '#efefef',
    marginBottom: '0px',
  },
  contentUser: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  iconUser: {
    fontSize: '70px',
  },
  containerUserInfo: {
    marginLeft: '12px',
  },
  textUserName: {
    fontSize: '22px',
    fontWeight: '800',
    marginBottom: '8px',
  },
  containerPhoneNumberVerif: {
    display: 'flex',
    flex: 1,
  },
  buttonCheckMobileNumber: {
    margin: '12px',
    padding: '12px',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: themesConfig.APP_YELLOW,
    borderRadius: '6px',
    marginBottom: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  buttonCheckMobileNumberText: {
    flex: 1,
    display: 'flex',
    fontWeight: 'bold',
    color: '#fff',
  },
  onlyOnMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
};
