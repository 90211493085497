export enum AppRouteId {
  LOGIN = 'LOGIN',
  HOME = 'HOME',

  REGISTER = 'REGISTER',

  OFFER_MENU = 'OFFER_MENU',
  PRODUCT_SELECTION = 'PRODUCT_SELECTION',

  ORDER_METHOD = 'ORDER_METHOD',
  ORDER_PAYMENT = 'ORDER_PAYMENT',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  USER_ACCOUNT_INFO = 'USER_ACCOUNT_INFO',
  DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
  PRIVATE_LIFE = 'PRIVATE_LIFE',
  CONTACT = 'CONTACT',

  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  PASSWORD_RESET = 'PASSWORD_RESET',

  VOUCHER = 'VOUCHER',
  SPONSORSHIP = 'SPONSORSHIP',

  EDIT_DELIVERY_ADDRESS = 'EDIT_DELIVERY_ADDRESS',
  ADD_DELIVERY_ADDRESS = 'ADD_DELIVERY_ADDRESS',
  ADD_DELIVERY_ADDRESS_END = 'ADD_DELIVERY_ADDRESS_END',

  ORDER_HISTORY = 'ORDER_HISTORY',
  ORDER_HISTORY_DETAILS = 'ORDER_HISTORY_DETAILS',
  BUSINESS_OFFER = 'BUSINESS_OFFER',

  CATEGORY_TAG = 'CATEGORY_TAG',
  CATEGORY_BUSINESS_OFFER = 'CATEGORY_BUSINESS_OFFER',
  CATEGORY = 'CATEGORY',

  SEARCH = 'SEARCH',
  SHOPPING_CART = 'SHOPPING_CART',
  PAYMENT_VALIDATION = 'PAYMENT_VALIDATION',

  OTHER = 'OTHER',
}
