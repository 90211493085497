import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';

export enum ConfirmAction {
  CONFIRM = 'confirm',
  ACCEPT = 'accept',
  CANCEL = 'cancel',
}

export interface ConfirmState {
  show: boolean;
  title: string | null;
  content: string | null;
  acceptText: string | null;
  cancelText: string | null;
}

const defaultConfirmState: ConfirmState = {
  show: false,
  title: null,
  content: null,
  acceptText: null,
  cancelText: null,
};

export const ConfirmContext = createContext<{
  dispatch?: Dispatch<ConfirmPayloadAction>;
  state: ConfirmState;
}>({ state: defaultConfirmState });

export type ConfirmPayload = Partial<typeof defaultConfirmState>;

export type ConfirmPayloadAction = {
  type: ConfirmAction;
  payload?: ConfirmPayload;
};

/**
 * @description Confirm Reducer that have 3 actions (confirm, accept, cancel)
 */
function confirmReducer(
  state = defaultConfirmState,
  action: ConfirmPayloadAction
): ConfirmState {
  switch (action.type) {
    case ConfirmAction.CONFIRM:
      return { ...state, ...action.payload, show: true };
    case ConfirmAction.ACCEPT:
    case ConfirmAction.CANCEL:
      return { ...defaultConfirmState };
    default:
      return state;
  }
}

/**
 * @description Provider component that provides confirm context
 */
export const ConfirmContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(confirmReducer, defaultConfirmState);

  return (
    <ConfirmContext.Provider value={{ dispatch, state }}>
      {children}
    </ConfirmContext.Provider>
  );
};
