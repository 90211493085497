import { Dispatch, SetStateAction } from 'react';

export enum EListNavigation {
  MY_COMMANDS,
  ACCOUNT_USER,
  ADRESSSE_DELIVERY,
  CART_PAYMENT,
  UPDATE_PASSWORD,
  HELP,
  ENTERPRISE_OFFER,
  MY_REWARD,
  CONTACT,
  PRIVACY,
  LOGOUT,
}

export interface IPropsData {
  menuList: Array<{ name: string; icon: any; url: string; id: EListNavigation }>;
  handleConfirmLogout: () => void;
  handleCancelLogout: () => void;
  handleClick: (item: any) => void;
  showModalLogout: boolean;
  setShowModalLogout: Dispatch<SetStateAction<boolean>>;
  userInfo: any;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}
