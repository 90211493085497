import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { stateAuthModal } from '../../../reducers/State/action';
import { EAuthModalTypes, IAuthModal } from '../../../reducers/State/types';
import { AppDispatch, useAppSelector } from '../../../store/store';
import HeaderComponent from '../components/header.components';
import { IHeaderComponentProps, IHeaderContainerProps } from '../types';

const HeaderContainer = (props: IHeaderContainerProps) => {
  const [showAdressBar, setShowAdressBar] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  // on click adress bar
  function onClickAdressBar() {
    setShowAdressBar(!showAdressBar);
  }

  const isAuthenticated = useAppSelector(state => state.auth.loggedIn);
  const dispatch = useDispatch<AppDispatch>();

  const openLoginModal = () => {
    const authModal: IAuthModal = {
      isOpen: true,
      type: EAuthModalTypes.LOGIN,
    };

    dispatch(stateAuthModal(authModal));
  };

  const headerComponentProps: IHeaderComponentProps = {
    isAuthenticated,
    openLoginModal,
    onClickAdressBar,
    openDrawer,
    handleDrawerToggle,
    ...props,
  };

  return <HeaderComponent {...headerComponentProps} />;
};

HeaderContainer.defaultProps = {
  showAddressBar: true,
  showButtons: true,
};

export default HeaderContainer;
