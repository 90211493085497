import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Container, Slide, Typography } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { styled } from '@mui/system';
import { useState } from 'react';
// import custom
import CloseIcon from '@mui/icons-material/Close';
import { IAroundMeComponentProps } from '../types';
import { styles } from './styles';

const StyledInputElement = styled('input')(
  () => `
  font-family: IBM Plex Sans, sans-serif;
  border: none;
  width:100%;
  background:none;
  &:hover {
    background: none;
    border-color:none;
  }
  &:focus {
    outline: none;
  }
`
);

const SearchOfferComponent = (props: IAroundMeComponentProps) => {
  const trigger = useScrollTrigger();
  const [search, setSearch] = useState('');

  return (
    <Slide in={!trigger}>
      <Box sx={styles.container}>
        <Container id="#container-search-offer">
          <Box sx={styles.containerInput}>
            <SearchIcon sx={{ fontSize: '18' }} />
            <form
              style={{ flex: 1, display: 'flex' }}
              onSubmit={() => {
                props.navigateSearch(search);
              }}
            >
              <StyledInputElement
                value={search}
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
                style={{
                  flex: 1,
                  marginLeft: '4px',
                }}
                id="input-with-icon-adornment"
                placeholder={'Plats, restaurant...'}
              />
            </form>
            {search.length > 0 && (
              <Button
                onClick={() => {
                  setSearch('');
                }}
                sx={{
                  borderRadius: '22px',
                  marginRight: '12px',
                  background: '#ddd',
                }}
              >
                <CloseIcon sx={{ color: '#fff' }} />
              </Button>
            )}
            {search.length > 0 && (
              <Button
                onClick={() => {
                  props.navigateSearch(search);
                }}
                sx={{
                  borderRadius: '22px',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  border: '2px solid #494949',
                }}
              >
                <Typography>Rechercher</Typography>
              </Button>
            )}
          </Box>
        </Container>
      </Box>
    </Slide>
  );
};

export default SearchOfferComponent;
