import {
  Box,
  Button,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';

// My script
import ErrorItems from '../../../error-items/error.items.component';
import { IPropsSettings } from '../types';
import { styles } from './styles';

const PhoneNumberFormScreen = (props: IPropsSettings) => {
  const { t } = useTranslation('M_PHONE_NUMBER_FORM');

  const {
    indicatifPhoneNumber,
    onValueChange,
    phoneNumber,
    setPhoneNumber,
    error,
    configPhoneNumber,
    submitForm,
    isLoad,
    onAction,
  } = props;

  return (
    <Box sx={styles.modalAddressContainer}>
      {isLoad && (
        <Box sx={{ padding: '12px' }}>
          <Skeleton animation="wave" width={'100%'} height={37} />
          <Skeleton animation="wave" variant="rectangular" width={'100%'} height={103} />
          <Skeleton animation="wave" width={'100%'} height={32} />
          <Skeleton animation="wave" width={'100%'} height={21} />
          <Skeleton animation="wave" width={'100%'} height={21} />
        </Box>
      )}

      {!isLoad && (
        <Box>
          {/**Title and description page */}
          <Typography sx={styles.title}>{capitalize(t('TITLE_VIEW'))}</Typography>
          <Typography sx={styles.description}>{capitalize(t('DESCRIPTION'))}</Typography>

          {/** Layout error */}
          {
            <Box style={{ margin: '12px', marginTop: '12px', marginBottom: '0px' }}>
              {error.map((index, key) => {
                return (
                  <ErrorItems
                    key={key}
                    data={{ text: index.text, action: onAction, id: index.id }}
                  />
                );
              })}
            </Box>
          }

          <Typography sx={styles.titleInput}>{capitalize(t('TITLE_INPUT'))}</Typography>

          {/** Input form phone number */}
          <Box sx={styles.containerPhoneNumber}>
            <Box sx={styles.pickerContainer}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={indicatifPhoneNumber}
                label="Indicatif"
                onChange={event => onValueChange(event.target.value.toString())}
              >
                {configPhoneNumber.map((index, key) => (
                  <MenuItem key={index} value={key}>
                    {index}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={styles.inputContainer}>
              <TextField
                id="outlined-name"
                label={capitalize(t('INPUT_PHONE_NUMBER'))}
                value={phoneNumber}
                sx={styles.noPadding}
                onChange={event => {
                  setPhoneNumber(event.target.value);
                }}
              />
            </Box>
          </Box>

          <Typography sx={styles.subTitle}>{capitalize(t('SUB_TITLE'))}</Typography>
        </Box>
      )}

      {/**Footer button action next screen */}
      <Box style={styles.footerContainer}>
        <Button onClick={submitForm} sx={styles.Button}>
          <Typography sx={styles.ButtonTitle}>{t('BUTTON_SUBMIT_TITLE')}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default PhoneNumberFormScreen;
