import { styled } from '@mui/system';
import themesConfig from '../../../../config/themes.config';
import { theme } from '../../../../themes';

export const Logo = styled('img')`
  height: 50px;
  image-rendering: -webkit-optimize-contrast;
`;

export const styles: any = {
  title: {
    fontSize: 16,
    fontWeight: '300',
    textAlign: 'center',
    marginTop: '22px',
    padding: '12px',
  },
  load: {
    flex: 1,
    display: 'flex',
  },
  formInput: {
    height: '74px',
    backgroundColor: 'transparent',
    transition: 'none',
    '&hover': {
      transition: 'none!important',
      backgroundColor: 'transparent!important',
    },
  },
  modalContent: {
    height: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
  },
  modalAddressContainer: {
    position: 'absolute',
    top: '50%',
    borderRadius: '6px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  containerButton: {
    marginTop: '32px',
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerButtonReSendCode: {
    margin: '12px',
    marginBottom: 0,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingBottom: '22px',
    borderColor: '#ddd',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  containerInput: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    alignSelf: 'center',
    height: '54px',
  },
  input: {
    position: 'relative',
    display: 'flex',
    fontSize: '22px',
    width: '54px',
    height: '54px',
    borderRadius: '6px',
    borderWidth: '2px',
    border: '1px solid #ddd',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputContent: {
    position: 'absolute',
    top: 0,
    opacity: 0,
  },
  footerContainer: {
    borderTopWidth: 0,
    display: 'flex',
    backgroundColor: 'transparent',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 0,
    marginTop: 6,
    flex: 1,
    paddingBottom: 12,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  Button: {
    backgroundColor: themesConfig.APP_YELLOW,
    marginTop: '22px',
    display: 'flex',
    padding: '12px',
    flex: 1,
    marginBottom: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  ButtonReSend: {
    borderWidth: '2px',
    borderColor: '#757575',
    borderRadius: '6px',
    backgroundColor: 'transparent',
  },
  ButtonTitleReSend: {
    color: '#757575',
  },
  ButtonTitle: {
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  buttonPlus: {
    display: 'flex',
    width: '50px',
    height: '50',
    borderColor: '#e0e0e0',
    borderWidth: '2px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '40px',
  },
} as const;
