import { styled } from '@mui/system';
import { theme } from '../../../themes';

export const Logo = styled('img')`
  height: 50px;
  image-rendering: -webkit-optimize-contrast;
`;

export const styles: any = {
  appBar: {
    padding: '5px',
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.1)',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  appBarContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  addressBanner: {
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonsContainer: {
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  cartButton: {
    paddingLeft: '12px',
    marginRight: '12px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  profileButton: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: '350px',
      maxWidth: '80%',
    },
  },
};
