/**
 * Custom layout format to card component
 *
 */

// React import
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Typography } from '@mui/material';

// My script
import { styles } from './styles';
import { IProps } from './types';

const ErrorItemsComponent = ({ data }: { data: IProps }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.containerText}>
        <Typography sx={styles.text}>{data.text}</Typography>
      </Box>
      <Box>
        <Button onClick={() => data.action(data.id)} style={styles.button}>
          <CloseIcon sx={{ fontSize: 16, color: '#424242' }} />
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorItemsComponent;
