import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CookieIcon from '@mui/icons-material/Cookie';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

// my import modules
import PhoneNumberFormScreen from '../../phone-number-verification/phone.number.form/containers/phone.number.form.screen.containers'; // eslint-disable-next-line max-len
import { IPropsData } from '../types';
import { styles } from './styles';

const SideMenu = (props: IPropsData) => {
  const { t } = useTranslation('M_ACCOUNT_SCREEN');
  const navigate = useNavigate();

  return (
    <div>
      <Box sx={styles.containerUser}>
        <Box sx={styles.contentUser}>
          <Box>
            <AccountCircleIcon sx={styles.iconUser} />
          </Box>
          <Box sx={styles.containerUserInfo}>
            <Typography sx={styles.textUserName}>
              {props.userInfo.firstName} {props.userInfo.lastName}
            </Typography>
            <Typography>{props.userInfo.email}</Typography>
            <Typography>{props.userInfo.defaultDeliveryAddress?.street}</Typography>
            <Typography>{props.userInfo.defaultDeliveryAddress?.zip}</Typography>
            <Typography>{props.userInfo.defaultDeliveryAddress?.city}</Typography>
          </Box>
        </Box>
      </Box>
      {/** IF USER ACCOUNT NOT VALIDATE NUMBER */}
      {props.userInfo.mobileNumberValid === false && (
        <Box sx={styles.containerPhoneNumberVerif}>
          <Button
            onClick={() => {
              props.setShowModal(true);
              /*navigation.navigate(
                EScreenName.phoneNumberForm, {mobileNumber:props.accountInfo.mobileNumber})*/
            }}
            sx={styles.buttonCheckMobileNumber}
          >
            <Typography sx={styles.buttonCheckMobileNumberText}>
              {t('PHONE_VERIFICATION_TEXT')}
            </Typography>
            <ArrowForwardIosIcon sx={{ color: '#fff', fontSize: '18px' }} />
          </Button>
        </Box>
      )}
      <List>
        <ListItemButton onClick={() => navigate('/mes-commandes')}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary={t('NAVIGATION_COMMANDS')} />
        </ListItemButton>
        <Divider />
        <ListItemButton>
          <ListItemText
            primary={t('NAVIGATION_MY_CITY') + ' : ' + props.userInfo.conciergerie?.name}
          />
        </ListItemButton>

        {props.menuList.map(item => (
          <ListItem button key={item.name} onClick={() => props.handleClick(item)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} sx={{ display: 'flex', flex: 1 }} />
            <ListItemIcon sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ArrowForwardIosIcon sx={{ color: '#bababa', fontSize: '18px' }} />
            </ListItemIcon>
          </ListItem>
        ))}
        <ListItemButton
          sx={styles.onlyOnMobile}
          component={Link}
          target="_blank"
          href="https://mw.please-it.com/assets/Please/legal_documents/mentions-legales.html"
        >
          <ListItemIcon>
            <PrivacyTipIcon />
          </ListItemIcon>
          <ListItemText primary={t('BUTTON_LEGAL_MENTIONS')} />
          <ListItemIcon sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ArrowForwardIosIcon sx={{ color: '#bababa', fontSize: '18px' }} />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton
          sx={styles.onlyOnMobile}
          component={Link}
          target="_blank"
          href="https://mw.please-it.com/assets/Please/legal_documents/politique-concernant-les-cookies.html" //eslint-disable-line
        >
          <ListItemIcon>
            <CookieIcon />
          </ListItemIcon>
          <ListItemText primary={t('BUTTON_COOKIE_MENTIONS')} />
          <ListItemIcon sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ArrowForwardIosIcon sx={{ color: '#bababa', fontSize: '18px' }} />
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton onClick={() => props.setShowModalLogout(true)}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={t('BUTTON_LOGOUT')} />
        </ListItemButton>
      </List>
      <Dialog
        open={props.showModalLogout}
        onClose={props.handleCancelLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('MODAL_LOGOUT_DESCRIPTION')}</DialogTitle>
        <DialogActions>
          <Button onClick={props.handleConfirmLogout}>
            {t('MODAL_LOGOUT_BUTTON_SUCCESS')}
          </Button>
          <Button onClick={props.handleCancelLogout}>
            {t('MODAL_LOGOUT_BUTTON_CANCEL')}
          </Button>
        </DialogActions>
      </Dialog>
      <PhoneNumberFormScreen
        data={{ showModal: props.showModal, setShowModal: props.setShowModal }}
      />
    </div>
  );
};

export default SideMenu;
