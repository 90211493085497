/**
 * Configure themes app
 */

// My script
import SERVICE_DEFAULT_IMAGE from '../assets/default-image/categorie-home-not-found.jpg';
import PRODUCT_DEFAULT_IMAGE from '../assets/default-image/product-not-found.jpg';
import APP_SHADOW from '../assets/logo/please-blanc-with-shadow.png';
import APP_WHITE from '../assets/logo/please-blanc.png';
import APP_WHITE_GREY from '../assets/logo/please-white-sub-grey.png';
import APP_COLOR from '../assets/logo/please.gris.png';
import { IConfig } from './types';

const APP_YELLOW = '#FFCD00';
const APP_PINK = '#E91E63';
const APP_PURPLE = '#673AB7';
const APP_BLUE = '#3F51B5';
const APP_GREEN = '#009688';
const LOGO_APP_COLOR = APP_COLOR;
const LOGO_WHITE_GREY_SUBTITLE = APP_WHITE_GREY;
const LOGO_APP_WHITE = APP_WHITE;
const LOGO_APP_SHADOW = APP_SHADOW;
const APP_NAME = 'please';
const NAME_OF_MODULES_PAYEMENT = 'paybox';
const BORDER_RADIUS = 6;

const themesConfig: IConfig = {
  APP_YELLOW,
  APP_PINK,
  APP_PURPLE,
  APP_BLUE,
  APP_GREEN,
  BORDER_RADIUS,
  LOGO_APP_COLOR,
  APP_NAME,
  LOGO_APP_WHITE,
  LOGO_WHITE_GREY_SUBTITLE,
  NAME_OF_MODULES_PAYEMENT,
  LOGO_APP_SHADOW,
  SERVICE_DEFAULT_IMAGE,
  PRODUCT_DEFAULT_IMAGE,
};

export default themesConfig;
