import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCartArticlesNumber } from '../../../api/cart.product.user.api';
import { stateRefreshCart } from '../../../reducers/State/action';
import { AppDispatch, RootState } from '../../../store/store';
import { styles } from './styles';

export const CartButton = () => {
  const [articlesNumber, setArticlesNumber] = useState(0);
  const [articleName, setArticleName] = useState('article');
  const refreshCart = useSelector((state: RootState) => state.state.refreshCart);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const articleNbr = getCartArticlesNumber();

    setArticlesNumber(articleNbr);
    setArticleName(articleNbr <= 1 ? 'article' : 'articles');
  }, []);

  useEffect(() => {
    if (refreshCart) {
      const articleNbr = getCartArticlesNumber();

      setArticlesNumber(articleNbr);
      setArticleName(articleNbr <= 1 ? 'article' : 'articles');

      const refreshCart = {
        refreshCart: false,
      };

      dispatch(stateRefreshCart(refreshCart));
    }
  }, [refreshCart]);

  return (
    <Button
      sx={styles.cartButton}
      startIcon={<ShoppingCartIcon />}
      component={Link}
      to={'/panier'}
    >
      {`${articlesNumber} ${articleName}`}
    </Button>
  );
};
