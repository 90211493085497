import {
  Analytics,
  AnalyticsCallOptions,
  getAnalytics,
  logEvent,
} from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { Location, matchPath } from 'react-router-dom';
import { firebaseConfig } from '../../config/firebase.config';
import { AppRoutePath } from '../../enums';
import { AppRouteId } from '../../enums/app-route-id.enum';

/**
 * @description Analytics instance generator
 */
export const analytics: Analytics = (() => {
  const app = initializeApp(firebaseConfig);

  return getAnalytics(app);
})();

/**
 * @description App routes by route id
 */
const appRoutes: {
  path: AppRoutePath;
  id: AppRouteId;
}[] = (() => {
  const ids = Object.keys(AppRouteId) as AppRouteId[];

  return ids.map((routeId: AppRouteId) => ({
    path: AppRoutePath[routeId],
    id: routeId,
  }));
})();

/**
 * @description Returns the associated screen and screen class for a given location
 */
function getScreenClassForLocation(location: Pick<Location, 'pathname'>): {
  screen: string | null;
  screen_class: string | null;
} {
  const matchedPath = appRoutes.find(route => matchPath(route.path, location.pathname));

  switch (matchedPath?.id) {
    case AppRouteId.LOGIN:
    case AppRouteId.REGISTER:
      return {
        screen: 'w_home_page_w_login',
        screen_class: 'w_home',
      };
    case AppRouteId.HOME:
      return {
        screen: 'w_home_screen',
        screen_class: 'w_home',
      };
    case AppRouteId.OFFER_MENU:
      return {
        screen: 'w_offer_restaurant_page',
        screen_class: 'w_offer',
      };
    case AppRouteId.HOME:
      return {
        screen: 'w_offer_restaurant_product_selection',
        screen_class: 'w_offer',
      };
    case AppRouteId.SHOPPING_CART:
      return {
        screen: 'w_basket_page',
        screen_class: 'w_basket',
      };
    case AppRouteId.ORDER_METHOD:
      return {
        screen: 'w_command_validation',
        screen_class: 'w_commande_wf',
      };
    case AppRouteId.ORDER_PAYMENT:
      return {
        screen: 'w_modalite_commande',
        screen_class: 'w_commande_wf',
      };
    case AppRouteId.PRODUCT_SELECTION:
      return {
        screen: 'w_offer_restaurant_product_selection',
        screen_class: 'w_offer',
      };
    case AppRouteId.PAYMENT_VALIDATION:
      return {
        screen: 'w_paiement_validate',
        screen_class: 'w_commande_wf',
      };
    case AppRouteId.CATEGORY_TAG:
      return {
        screen: 'w_home_screen_tag',
        screen_class: 'w_home_tag',
      };
    case AppRouteId.CATEGORY_BUSINESS_OFFER:
      return {
        screen: 'w_home_screen_business',
        screen_class: 'w_home_bus',
      };
    case AppRouteId.CATEGORY:
      return {
        screen: 'w_home_screen_cat',
        screen_class: 'w_home_cat',
      };
    default:
      return {
        screen: null,
        screen_class: null,
      };
  }
}

/**
 * @description Track a given screen location
 */
export const trackScreenView = (
  location: Pick<Location, 'pathname'>,
  eventParams: {
    [key: string]: any;
  } = {},
  options?: AnalyticsCallOptions
) => {
  const { screen, screen_class } = getScreenClassForLocation(location);

  if (screen === null || screen_class === null) {
    return;
  }

  logEvent(
    analytics,
    'screen_view',
    {
      firebase_screen: screen,
      firebase_screen_class: screen_class,
      ...eventParams,
    },
    options
  );
};

/**
 * @description Function to match the name of trackScreenName function
 */
export const trackEvent: typeof logEvent = logEvent;
