export enum CreditCardType {
  AMERICAN_EXPRESS = 'americanExpress',
  DINERS_CLUB = 'dinersClub',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MAESTRO = 'maestro',
  MASTERCARD = 'mastercard',
  UNION_PAY = 'unionPay',
  UNKNOWN = 'unknown',
  VISA = 'visa',
  VISA_ELECTRON = 'visaElectron',
}
