import { FirebaseOptions } from 'firebase/app';
import { EConfigEnvironement } from './api.config';

const prodFirebaseConfig = {
  apiKey: 'AIzaSyDB8JFw2mAijQdV3zk91qBgTMOKE1IOuBw',
  authDomain: 'please-v2.firebaseapp.com',
  databaseURL: 'https://please-v2.firebaseio.com',
  projectId: 'please-v2',
  storageBucket: 'please-v2.appspot.com',
  messagingSenderId: '342186733772',
  appId: '1:342186733772:web:a21a8165533f5f2a169269',
  measurementId: 'G-461D6YMDS8',
};

const devFirebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAEIoBCvCxm_fVgbvF_QhYQCx9KCq49NKw',
  authDomain: 'app-pub-test.firebaseapp.com',
  projectId: 'app-pub-test',
  storageBucket: 'app-pub-test.appspot.com',
  messagingSenderId: '827070713928',
  appId: '1:827070713928:web:3300baf7b842285a8c887d',
  measurementId: 'G-N09YFVNGT5',
};

const preProdFirebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAEIoBCvCxm_fVgbvF_QhYQCx9KCq49NKw',
  authDomain: 'app-pub-test.firebaseapp.com',
  projectId: 'app-pub-test',
  storageBucket: 'app-pub-test.appspot.com',
  messagingSenderId: '827070713928',
  appId: '1:827070713928:web:7b32f143d74557468c887d',
  measurementId: 'G-2VF9YCTWKD',
};

const firebaseConfigs: Record<EConfigEnvironement, FirebaseOptions> = {
  [EConfigEnvironement.dev]: devFirebaseConfig,
  [EConfigEnvironement.preProd]: preProdFirebaseConfig,
  [EConfigEnvironement.prod]: prodFirebaseConfig,
  [EConfigEnvironement.mockup]: devFirebaseConfig,
};

const env = (process.env.REACT_APP_ENV || process.env.NODE_ENV) as EConfigEnvironement;

export const firebaseConfig = firebaseConfigs[env];
